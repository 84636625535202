/*.company-box::after{*/
    /*background-image: none !important;*/
/*}*/
.page-sidebar .sidebar-menu .menu-items > li > a > .title{
    width: 79% !important;
}


.fa-2{
    font-size: 2em !important;
}
.fa-3{
    font-size: 3em !important;
}
.fa-4{
    font-size: 4em !important;
}
.fa-5{
    font-size: 5em !important;
}
.table tbody tr td{
    --vertical-align: middle !important;
}

.facebook-post-table tbody tr td {
    height: 40px !important;
}

/* DATA TABLE*/
.dataTable{
    border: 1px solid #f1f1f1 !important;
}
.dataTable thead tr {
    background-color: #8a7dbe !important;
}
.dataTable thead{
    color: white !important;
}

.panel-heading h3{
    border-radius: 10px;
    --box-shadow: 0 0 0 1px rgba(53, 86, 129, 0.4) inset, 0 0 5px rgba(53, 86, 129, 0.5) inset, -285px 0 35px white inset;
    color: white;
    --font-family: "Titillium Web",sans-serif;
    font-size: 20px !important;
    font-weight: bold;
    line-height: 40px;
    margin: 1em 0 0.5em;
    padding: 15px 15px 15px 15px;
    position: relative;
    background-color: #0ebfe9 !important;
}
.inline{
    display: inline-block !important;
}
.active-led{
    color: #008000;
}
.inactive-led{
    color: red;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
    color:rgba(98, 98, 98, 0.8) !important;
}

